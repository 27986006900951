

































































































































































































.main-wrapper {
    height: 75%;
    padding: 0 30px 0 10px;     
     .title{
        text-align: center;
        padding-bottom: 30px;
        .titleData{
            font-weight: bold; 
            font-size: 14px;
        }
    }  
    .el-scrollbar {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden;    
             .el-scrollbar__view {
                height: 100%;
            }
        }
    }
    .select-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .select-left {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
                .text {
                font-size: 14px;
                color: #333333;
                margin-right: 10px;
            }
        }           
    }
    .footer-button{
        text-align: center;
        padding-top: 15px;
    } 
}
